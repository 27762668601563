@import "~@ui/styles/tools";

.arrows {
    position: absolute;
    top: 50%;
    padding: 10px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.75);
    border: none;
    z-index: 2;
    opacity: 0;
    transition: 0.5s ease;

    &:hover {
        cursor: pointer;
        opacity: 1;
    }
}

.arrow-right {
    right: 20px;
}

.arrow-left {
    left: 20px;
}

.item-wrapper {
    position: relative;
    margin: 0 20px;
    padding-bottom: 30px;
    height: 100%;
}
